<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';
import { DateTime } from 'luxon';
import VueDatePicker from '@vuepic/vue-datepicker';
import { nanoid } from 'nanoid';
import { animations } from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/vue';
import { useModal } from 'vue-final-modal';
import api from '@/services/api';
import useModalFeatures from '@/composables/useModalFeatures';
import useLoader from '@/composables/useLoader';
import useTask from '@/composables/useTask';
import useDate from '@/composables/useDate';

import {
  AppAlert,
  AppButton,
  AppCloseButton,
  AppLoader,
  FontIcon,
  FormInput,
  FormLabel,
  FormMinutesDuration,
  FormWeekPicker,
  HelpInformation,
  InternalProjectActivityNoteModal,
} from '@/components';

import { IUserPreviewResource, UserStatusType } from '@/types/User';
import {
  IInternalProjectActivityCreateOrUpdateRequestBody,
  IInternalProjectCreateOrUpdateRequestBody,
  InternalProjectStatusType,
} from '@/types/InternalProject';
import { ActivityGroupType, IActivityGroupListResource, IActivityTemplateListResource } from '@/types/Activity';
import { ITimePeriodResource } from '@/types/TimePeriod';

type Props = {
  id?: number;
};
import { ConfirmDialogConfirmParams, SetLoading } from '@/types/Common';

type InternalProjectActivity = IInternalProjectActivityCreateOrUpdateRequestBody & { uid: string };

const { t, locale } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();
const loader = useLoader();
const { taskFrequencyOptions } = useTask();
const { convertWeekNumberToDateRange, getFirstDayOfWeek, getLastDayOfWeek } = useDate();

const props = defineProps<Props>();

const status = ref<null | InternalProjectStatusType>(null);
const name = ref<null | string>(null);

const form = reactive<Omit<IInternalProjectCreateOrUpdateRequestBody, 'activities'>>({
  start_date: DateTime.now().toFormat('yyyy-MM-dd'),
  end_date: null,
  name: null,
  activity_group_id: null,
});

const [activitiesNodeRef, activities, updateActivitiesConfig] = useDragAndDrop<InternalProjectActivity>([]);

const emit = defineEmits<{
  closed: [];
  create: [form: IInternalProjectCreateOrUpdateRequestBody, params: ConfirmDialogConfirmParams];
  update: [id: number, form: IInternalProjectCreateOrUpdateRequestBody, params: ConfirmDialogConfirmParams];
  activate: [id: number, name: string, params: ConfirmDialogConfirmParams];
  close: [id: number, name: string, params: ConfirmDialogConfirmParams];
}>();

const loading = ref(false);

const setLoading: SetLoading = (value: boolean) => {
  loading.value = value;
};

const users = ref<IUserPreviewResource[]>([]);
const usersLoading = ref(false);

const activityGroups = ref<IActivityGroupListResource[]>([]);
const activityGroupsLoading = ref(false);

const timePeriods = ref<ITimePeriodResource[]>([]);
const timePeriodsLoading = ref(false);

const activityTemplates = ref<IActivityTemplateListResource[]>([]);
const activityTemplatesLoading = ref(false);

const disabled = computed(() => !form.name || !form.start_date || !form.activity_group_id);

const disabledActivityGroup = computed(() => activities.value.some((activity) => !!activity.id));

const minStartDate = computed(() => {
  if (timePeriods.value.length === 0) return '';
  const latestClosedTimePeriod = timePeriods.value.find((timePeriod) => timePeriod.status === 'closed');
  if (latestClosedTimePeriod) {
    return DateTime.fromObject({ year: latestClosedTimePeriod.year, month: latestClosedTimePeriod.month, day: 1 })
      .plus({ months: 1 })
      .startOf('month')
      .toJSDate();
  }
  const earliestOpenedTimePeriod = timePeriods.value.at(-1);
  if (!earliestOpenedTimePeriod) return '';
  return DateTime.fromObject({ year: earliestOpenedTimePeriod.year, month: earliestOpenedTimePeriod.month, day: 1 })
    .startOf('month')
    .toJSDate();
});

const maxStartDate = computed(() => {
  const startWeeks = activities.value
    .map((activity) => activity.start_week)
    .filter((startWeek) => typeof startWeek === 'number') as number[];
  if (startWeeks.length > 0) {
    const [, maxAllowedDate] = convertWeekNumberToDateRange(Math.min(...startWeeks));
    return maxAllowedDate;
  }
  if (form.end_date) {
    return DateTime.fromISO(form.end_date).toJSDate();
  }
  return '';
});

const minEndDate = computed(() => {
  if (form.start_date) {
    return DateTime.fromISO(form.start_date).toJSDate();
  }
  return '';
});

function submit() {
  if (props.id) {
    emit(
      'update',
      props.id,
      {
        ...form,
        activities: activities.value,
      },
      { setLoading },
    );
  } else {
    emit(
      'create',
      {
        ...form,
        activities: activities.value,
      },
      { setLoading },
    );
  }
}

async function getActivityGroups() {
  try {
    activityGroupsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('type', ActivityGroupType.INTERNAL_WORK);
    const response = await api.activityGroups.index({ searchParams });
    activityGroups.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    activityGroupsLoading.value = false;
  }
}

async function getUsers() {
  try {
    usersLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', UserStatusType.Active);
    searchParams.append('statuses[]', UserStatusType.Invited);
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    usersLoading.value = false;
  }
}

async function getTimePeriods() {
  try {
    timePeriodsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.timePeriods.index({ searchParams });
    timePeriods.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    timePeriodsLoading.value = false;
  }
}

function onAddActivity() {
  activities.value.push({
    uid: nanoid(),
    template_id: null,
    frequency: null,
    start_week: Number(DateTime.fromISO(form.start_date).toFormat('kkkkWW')),
    users_id: [],
    scheduled_time: null,
    order: activities.value.length + 1,
    note: null,
  });
}

function onDeleteActivity(uid: string) {
  activities.value = activities.value.filter((activity) => activity.uid !== uid);
  reorder();
}

function onNoteActivity(activity: InternalProjectActivity) {
  const { open, close, destroy } = useModal({
    component: InternalProjectActivityNoteModal,
    attrs: {
      initialNote: activity.note,
      onSave(note: InternalProjectActivity['note']) {
        activity.note = note;
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function reorder() {
  activities.value.forEach((activity, index) => {
    activity.order = index + 1;
  });
}

async function getActivityTemplates() {
  if (!form.activity_group_id) return;
  try {
    activityTemplatesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.activityTemplates.index(form.activity_group_id, { searchParams });
    activityTemplates.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    activityTemplatesLoading.value = false;
  }
}

async function onActivityGroupChange() {
  activities.value.forEach((activity) => {
    activity.template_id = null;
  });
  await getActivityTemplates();
}

async function onActivityTemplateChange(activity: InternalProjectActivity) {
  activity.frequency =
    activityTemplates.value.find((template) => template.id === activity.template_id)?.default_frequency ?? null;
}

onMounted(async () => {
  if (props.id) {
    loader.start();
    try {
      const response = await api.internalProjects.get(props.id);
      status.value = response.data.status;
      name.value = response.data.name;
      // Set form
      form.name = response.data.name;
      form.start_date = response.data.start_date;
      form.end_date = response.data.end_date;
      form.activity_group_id = response.data.activity_group.id;
      // Set activities
      activities.value = response.data.activities.map((activity) => ({
        uid: nanoid(),
        ...activity,
        users_id: activity.users.map((user) => user.uuid),
        template_id: activity.template.id,
      }));
    } catch (error) {
      console.error(error);
    }
    await Promise.all([getActivityTemplates(), getTimePeriods(), getActivityGroups(), getUsers()]);
    loader.finish();
  } else if (!props.id) {
    await Promise.all([getTimePeriods(), getActivityGroups(), getUsers()]);
  }
  updateActivitiesConfig({ plugins: [animations()], handleEnd: reorder });
});

onCtrlEnter(() => {
  if (!disabled.value) {
    submit();
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-huge"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <form v-else @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <div class="d-flex align-items-end">
          <template v-if="id">
            <h2 class="mb-0" v-text="t('internal-projects.edit.title')" />
            <HelpInformation class="ml-1" translation="internal-projects.edit.help" />
          </template>
          <template v-else>
            <h2 class="mb-0" v-text="t('internal-projects.create.title')" />
            <HelpInformation class="ml-1" translation="internal-projects.create.help" />
          </template>
        </div>
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <div class="row row-cols-md-2 row-cols-lg-4 align-items-center">
          <!-- Name -->
          <div class="col form-group">
            <FormLabel html-for="name" required>{{ t('internal-projects.attributes.name') }}</FormLabel>
            <FormInput v-model="form.name" id="name" required />
          </div>
          <!-- Activity group -->
          <div class="col form-group">
            <FormLabel html-for="activity_group" required>
              {{ t('internal-projects.attributes.activity_group') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="form.activity_group_id"
              :options="activityGroups"
              :loading="activityGroupsLoading"
              :disabled="activityGroupsLoading || disabledActivityGroup"
              label="name"
              input-id="activity_group"
              :reduce="(option: IActivityGroupListResource) => option.id"
              :placeholder="t('common.select')"
              required
              @update:model-value="onActivityGroupChange"
            />
          </div>
          <!-- Start date -->
          <div class="col form-group">
            <div class="form-wrapper has-icon">
              <FormLabel html-for="dp-input-start_date" required>
                {{ t('internal-projects.attributes.start_date') }}
              </FormLabel>
              <VueDatePicker
                uid="start_date"
                :ui="{ input: 'form-control' }"
                :placeholder="t('common.select')"
                v-model="form.start_date"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                required
                auto-apply
                text-input
                :min-date="minStartDate"
                :max-date="maxStartDate"
                :locale="locale"
                week-numbers="iso"
                :week-num-name="t('common.week_short')"
                :clearable="false"
                :disabled="timePeriodsLoading"
                teleport
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- End date -->
          <div class="col form-group">
            <div class="form-wrapper has-icon">
              <FormLabel html-for="dp-input-end_date">
                {{ t('internal-projects.attributes.end_date') }}
              </FormLabel>
              <VueDatePicker
                uid="end_date"
                :ui="{ input: 'form-control' }"
                :placeholder="t('common.select')"
                v-model="form.end_date"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :min-date="minEndDate"
                :locale="locale"
                week-numbers="iso"
                :week-num-name="t('common.week_short')"
                clearable
                :disabled="timePeriodsLoading"
                position="right"
                teleport
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
        </div>
        <!-- Activities -->
        <h3 class="mt-3">{{ t('internal-projects.activities.title') }}</h3>
        <AppAlert v-if="activities.length === 0">
          {{ t('internal-projects.activities.empty') }}
        </AppAlert>
        <AppAlert class="mt-2" type="warning" v-if="!form.activity_group_id">
          {{ t('internal-projects.messages.choose_activity_group') }}
        </AppAlert>
        <div v-if="activities.length > 0" class="records activity-records">
          <section class="records-section">
            <div class="records-row records-header">
              <div class="records-cell records-cell-task">
                {{ t('internal-projects.activities.table.name') }}
                <span class="text-danger-500">*</span>
              </div>
              <div class="records-cell records-cell-frequency">
                {{ t('internal-projects.activities.table.frequency') }}
                <span class="text-danger-500">*</span>
              </div>
              <div class="records-cell records-cell-start-week">
                {{ t('internal-projects.activities.table.start_week') }}
                <span class="text-danger-500">*</span>
              </div>
              <div class="records-cell records-cell-estimated-time">
                {{ t('internal-projects.activities.table.scheduled_time') }}
                <span class="text-danger-500">*</span>
              </div>
              <div class="records-cell records-cell-employees">
                {{ t('internal-projects.activities.table.users') }}
                <span class="text-danger-500">*</span>
              </div>
              <div class="records-cell records-cell-actions text-right">
                {{ t('common.actions') }}
              </div>
            </div>
          </section>
          <section class="records-section bordered" ref="activitiesNodeRef">
            <div class="records-row hoverable" v-for="activity in activities" :key="activity.uid">
              <div class="records-cell records-cell-task">
                <div class="d-flex align-items-center">
                  <i class="ti ti-grip-vertical flex-shrink-0 text-3 text-neutral-400 pr-1 pointer" />
                  <VueSelect
                    class="flex-grow-1"
                    :clearable="false"
                    v-model="activity.template_id"
                    :options="activityTemplates"
                    :loading="activityTemplatesLoading"
                    label="name"
                    :reduce="(option: IActivityTemplateListResource) => option.id"
                    :placeholder="t('common.select')"
                    required
                    @update:model-value="onActivityTemplateChange(activity)"
                  />
                </div>
              </div>
              <div class="records-cell records-cell-frequency">
                <VueSelect
                  :clearable="false"
                  :searchable="false"
                  v-model="activity.frequency"
                  :options="taskFrequencyOptions"
                  label="label"
                  :reduce="(option: any) => option.value"
                  :placeholder="t('common.select')"
                />
              </div>
              <div class="records-cell records-cell-start-week">
                <FormWeekPicker
                  v-model="activity.start_week"
                  :min-date="form.start_date ? getFirstDayOfWeek(form.start_date) : ''"
                  :max-date="form.end_date ? getLastDayOfWeek(form.end_date) : ''"
                  teleport
                  required
                />
              </div>
              <div class="records-cell records-cell-estimated-time">
                <FormMinutesDuration :max="5985" v-model="activity.scheduled_time" />
              </div>
              <div class="records-cell records-cell-employees">
                <VueSelect
                  :clearable="false"
                  v-model="activity.users_id"
                  :options="users"
                  :selectable="(option: IUserPreviewResource) => !activity.users_id.includes(option.uuid)"
                  :reduce="(option: IUserPreviewResource) => option.uuid"
                  label="name"
                  :placeholder="t('common.select')"
                  :disabled="usersLoading"
                  :loading="usersLoading"
                  multiple="multiple"
                  :clear-search-on-blur="() => true"
                  :close-on-select="false"
                />
              </div>
              <div class="records-cell records-cell-actions nowrap text-right">
                <AppButton
                  v-tooltip.left="t('internal-projects.activities.tooltip.add_people')"
                  @click.stop.prevent="onDeleteActivity(activity.uid)"
                  class="d-none ml-2"
                  size="small"
                  light
                  circle
                  disabled
                >
                  <FontIcon name="users-plus" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('internal-projects.activities.tooltip.note')"
                  @click.stop.prevent="onNoteActivity(activity)"
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="note" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('common.delete')"
                  @click.stop.prevent="onDeleteActivity(activity.uid)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </div>
            </div>
          </section>
        </div>
        <AppButton
          class="mt-3"
          v-tooltip.right="t('common.add')"
          color="success"
          @click.prevent="onAddActivity"
          light
          circle
          :disabled="!form.activity_group_id"
        >
          <FontIcon name="plus" />
        </AppButton>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <div>
            <AppButton light @click.prevent="close" :disabled="loading">
              {{ t('common.cancel') }}
            </AppButton>
            <AppButton
              v-if="props.id && status === InternalProjectStatusType.ACTIVE"
              class="ml-2"
              color="danger"
              @click.prevent="emit('close', props.id, name!, { setLoading })"
              :disabled="loading"
            >
              {{ t('common.close') }}
            </AppButton>
          </div>
          <div class="ml-auto">
            <AppButton
              v-if="0 && props.id && status === InternalProjectStatusType.DRAFT"
              class="ml-2"
              color="secondary"
              @click.prevent="emit('activate', props.id, name!, { setLoading })"
              :disabled="loading"
            >
              {{ t('common.activate') }}
            </AppButton>
            <AppButton class="ml-2" :color="id ? 'success' : 'secondary'" :loading="loading" :disabled="disabled">
              {{ id ? t('common.update') : t('common.create') }}
            </AppButton>
          </div>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.activity-records {
  .records-cell {
    &-task {
      flex-basis: 20%;
      max-width: calc(100% - 20%);
    }

    &-frequency {
      flex-basis: 15%;
      max-width: calc(100% - 15%);
    }

    &-start-week {
      flex-basis: 10%;
      max-width: calc(100% - 10%);
    }

    &-estimated-time {
      flex-basis: 10%;
      max-width: calc(100% - 10%);
    }

    &-employees {
      flex-basis: 35%;
      max-width: calc(100% - 35%);
    }

    &-actions {
      flex-basis: 10%;
      max-width: calc(100% - 10%);
    }
  }
}
</style>
