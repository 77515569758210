<script setup lang="ts">
import { computed, reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';
import { DateTime } from 'luxon';
import api from '@/services/api';
import { AppAlert, AppButton, FormLabel } from '@/components';
import { IFinancialYearResource, IFinancialYearRequestBody } from '@/types/FinancialYear';
import useLoading from '@/composables/useLoading';

const { t, locale } = useI18n({ useScope: 'global' });
const { loading, setLoading } = useLoading();

type Props = {
  clientUuid: string;
  financialYear?: IFinancialYearResource;
  initialStartDate?: string;
  disabledStartDate?: boolean;
};

const { clientUuid, financialYear, initialStartDate, disabledStartDate } = defineProps<Props>();

const form = reactive<IFinancialYearRequestBody>({
  start_date: '',
  end_date: '',
});

const emit = defineEmits<{
  closed: [];
  updated: [financialYear: IFinancialYearResource];
  created: [financialYear: IFinancialYearResource];
}>();

const disabled = computed(() => !form.start_date || !form.end_date);

async function submit() {
  try {
    setLoading(true);
    if (financialYear) {
      const response = await api.financialYears.update(clientUuid, financialYear.uuid, form);
      emit('updated', response.data);
    } else {
      const response = await api.financialYears.store(clientUuid, form);
      emit('created', response.data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}

onMounted(() => {
  if (financialYear) {
    form.start_date = DateTime.fromISO(financialYear.start_date).toFormat('yyyy-MM-dd');
    form.end_date = DateTime.fromISO(financialYear.end_date).toFormat('yyyy-MM-dd');
  } else {
    if (initialStartDate) {
      form.start_date = initialStartDate;
      form.end_date = DateTime.fromISO(initialStartDate).plus({ months: 12 }).minus({ days: 1 }).toFormat('yyyy-MM-dd');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    content-style="overflow: visible;"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="financialYear ? t('financial-year.edit.title') : t('financial-year.create.title')" />
      </div>
      <div class="modal-content">
        <!-- Start date -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="dp-input-start_date" required>
                {{ t('financial-year.attributes.start_date') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <div class="form-wrapper has-icon">
                <VueDatePicker
                  uid="start_date"
                  :ui="{ input: 'form-control' }"
                  :placeholder="t('common.select')"
                  v-model="form.start_date"
                  model-type="format"
                  auto-apply
                  format="yyyy-MM-dd"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  required
                  text-input
                  :locale="locale"
                  :week-num-name="t('common.week_short')"
                  :max-date="form.end_date ?? ''"
                  :clearable="false"
                  :disabled="disabledStartDate"
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>
        <!-- End date -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="dp-input-end_date" required>
                {{ t('financial-year.attributes.end_date') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <div class="form-wrapper has-icon">
                <VueDatePicker
                  uid="end_date"
                  :ui="{ input: 'form-control' }"
                  :placeholder="t('common.select')"
                  v-model="form.end_date"
                  model-type="format"
                  auto-apply
                  format="yyyy-MM-dd"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  required
                  :min-date="form.start_date ?? ''"
                  text-input
                  :locale="locale"
                  :clearable="false"
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>
        <AppAlert v-if="financialYear" type="warning">
          {{ t('financial-year.message.editing_can_have_effect') }}
        </AppAlert>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <AppButton light @click.prevent="close">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton
            class="ml-auto"
            :color="financialYear ? 'success' : 'secondary'"
            :disabled="disabled"
            :loading="loading"
          >
            {{ financialYear ? t('common.update') : t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
