import { IUserPreviewResource } from '@/types/User';
import { ClientInvoiceLanguage, IClientContact, IClientPreviewResource } from '@/types/Client';
import { IProjectPreviewResource } from '@/types/Project';
import { IErpArticleResource } from '@/types/Article';
import { IInvoiceItemTemplateResource } from '@/types/InvoiceItemTemplate';
import { ITimePeriodResource } from '@/types/TimePeriod';
import { ErpType } from '@/types/Erp';

export enum InvoiceInternalStatusType {
  DRAFT = 'draft',
  APPROVED = 'approved',
  DELETED = 'deleted',
  UNMATCHED = 'unmatched',
  MATCHED = 'matched',
  POSTPONED = 'postponed',
  ACTION_REQUIRED = 'action_required',
  EMPTY = 'empty',
}

export enum InvoiceExternalStatusType {
  NOT_SENT = 'NotSent',
  UNPAID = 'Unpaid',
  OVERDUE_REMINDER_SENT = 'OverdueReminderSent',
  OVERDUE_REMINDER_NOT_SENT = 'OverdueReminderNotSent',
  PAID = 'Paid',
  CANCELLED = 'Cancelled',
  COLLECTION = 'Collection',
  EXPIRES = 'Expires',
}

export enum InvoiceSyncStatusType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum InvoiceItemPriceType {
  FIXED = 'fixed',
  HOURLY = 'hourly',
  ADVANCE = 'advance',
  EXTRA_FEE = 'extra_fee',
  DISCOUNT = 'discount',
  OUTLAYS = 'outlays',
}

export type InvoiceItemUnitType = 'pieces' | 'hours';

export enum InvoiceDistributionType {
  E_INVOICE = 'e_invoice',
  EMAIL = 'email',
  MAIL = 'mail',
}

export interface IInvoiceBillingAddress {
  first_address: string;
  second_address: string;
  zip: string;
  city: string;
  country: string;
  email: string;
}

export interface IInvoiceItemPostponeResource {
  id: number;
  quantity: number;
  price_per_unit: number;
  comment: null | string;
  sum: number;
}

export interface IInvoiceItemWriteOffResource {
  id: number;
  quantity: number;
  price_per_unit: number;
  comment: null | string;
  sum: number;
}

export interface IInvoiceItemResource {
  id: number;
  price_type: string;
  description: null | string;
  quantity: number;
  original_quantity: number;
  price_per_unit: number;
  discount: number;
  custom: boolean;
  total_price: number;
  is_inverted_amount: boolean;
  project: IProjectPreviewResource;
  erp_article: null | IErpArticleResource;
  invoice_item_template: null | IInvoiceItemTemplateResource;
  postpone: null | IInvoiceItemPostponeResource;
  write_off: null | IInvoiceItemWriteOffResource;
  invoiced_period: { end: string; start: string };
}

export interface IInvoiceResource {
  id: number;
  client: IClientPreviewResource;
  user: IUserPreviewResource;
  created_at: string;
  internal_status: InvoiceInternalStatusType;
  clientContact: IClientContact;
  billing_address: IInvoiceBillingAddress;
  external_status: null | InvoiceExternalStatusType;
  external_amount: null | number | string;
  sync_status: null | InvoiceSyncStatusType;
  sync_error_message: null | string;
  other_info: null | string;
  language: ClientInvoiceLanguage;
  internal_note: null | string;
  invoice_text: null | string;
  invoiceItems: IInvoiceItemResource[];
  invoice_distribution: null | InvoiceDistributionType;
  time_period: ITimePeriodResource;
  system: ErpType;
  price: null | number | string;
  is_meta_exists: boolean;
}

export interface IInvoicePreviewResource
  extends Pick<
    IInvoiceResource,
    'id' | 'client' | 'user' | 'created_at' | 'sync_status' | 'internal_status' | 'external_status'
  > {
  updated_at: string;
  sync_at: string;
  price: number;
  external_amount: number;
}

export interface IInvoiceCreateRequestBody {
  client_uuid: string;
}

export interface IInvoiceItemRequestBody {
  id: number;
  erp_article_id: number | null;
  description: string;
  quantity: number;
  discount: number;
  postpone: {
    quantity: number;
    comment: string;
  };
  write_off: {
    quantity: number;
    comment: string;
  };
}

export interface IInvoiceCustomItemRequestBody {
  id?: number;
  project_id: null | number;
  erp_article_id: null | number;
  quantity: number;
  price_per_unit: number;
  description: string;
  invoice_item_template_id: null | number;
  discount: number;
  postpone: {
    quantity: number;
    comment: string;
  };
  write_off: {
    quantity: number;
    comment: string;
  };
}

export interface IInvoiceRequestBody {
  client_contact_uuid: null | string;
  user_uuid: null | string;
  language: ClientInvoiceLanguage;
  internal_note: null | string;
  other_info: null | string;
  invoice_text: null | string;
  invoice_items: IInvoiceItemRequestBody[];
  custom_invoice_items: IInvoiceCustomItemRequestBody[];
}

export interface IFetchNotInvoicedWorksRequest {
  client: string;
  projects: number[];
  add_to_existing_invoices: boolean;
  time_period?: number;
}

export interface IInvoicesIsGeneratingBody {
  generating: boolean;
}
