<script setup lang="ts">
import { computed, inject, onMounted, reactive, ref } from 'vue';
import VueSelect from 'vue-select';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { nanoid } from 'nanoid';
import { DateTime } from 'luxon';

import useLoader from '@/composables/useLoader';
import useProjectBillingPlans from '@/composables/useProjectBillingPlans';
import useDate from '@/composables/useDate';
import useTrackChanges from '@/composables/useTrackChanges';
import useProjectStore from '@/store/ProjectStore';
import api from '@/services/api';
import toast from '@/services/toast';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  BillingPlansGrid,
  FontIcon,
  FormInput,
  FormLabel,
} from '@/components';
import { BillingFrequency, ProjectPriceType } from '@/types/Common';
import { IBillingCell, IProjectBillingPlan, IProjectBillingPlanResource, ProjectStep } from '@/types/Project';
import { IServiceResource } from '@/types/Service';
import useHelpers from '@/composables/useHelpers';

const projectId = inject<number>('projectId');
const clientUuid = inject<string>('clientUuid');

const projectStore = useProjectStore();
const { setProject, lockTabs, unlockTabs } = projectStore;
const { project, isDraft, isDone, isCancelled, isActive } = storeToRefs(projectStore);

const { t, n } = useI18n({ useScope: 'global' });
const { createBillingCells, createBillingPlans, calculateBillingCount, splitFee } = useProjectBillingPlans();
const { findEarliestAndLatestDates } = useDate();
const { parseAmount } = useHelpers();
const loader = useLoader({ useProgress: false });
const nextLoader = useLoader({ useProgress: false });
const saveLoader = useLoader({ useProgress: false });

const emit = defineEmits<{
  'change-step': [step: ProjectStep];
}>();

const submitMode = ref<'back' | 'update' | 'next'>('next');

const service = ref<null | IServiceResource>(null);

const billingCells = ref<IBillingCell[]>([]);
const billingPlans = ref<IProjectBillingPlan[]>([]);
const noPlannedBillingPlans = ref(false);

type Form = {
  priceType: null | ProjectPriceType;
  billingFrequency: null | BillingFrequency;
  projectFee: null | number;
  discount: number;
  plannedBillingPlans: IProjectBillingPlan[];
};

const form = reactive<Form>({
  priceType: null,
  billingFrequency: null,
  projectFee: null,
  discount: 0,
  plannedBillingPlans: [],
});

const tracker = useTrackChanges(form);

const disabledUpdate = computed(() => {
  return nextLoader.isLoading.value;
});

const disabledNext = computed(() => {
  return saveLoader.isLoading.value;
});

const invoiced = computed(() => {
  return billingPlans.value
    .filter(({ is_invoiced }) => is_invoiced)
    .reduce((total, { price }) => total + parseAmount(price), 0);
});

const scheduled = computed(() => (draft?: boolean) => {
  return (draft ? form.plannedBillingPlans.filter(({ is_deleted }) => !is_deleted) : billingPlans.value)
    .filter(({ is_invoiced }) => !is_invoiced)
    .reduce((total, { price }) => total + parseAmount(price), 0);
});

const feeSummary = computed(() => (draft?: boolean) => {
  return (draft ? form.plannedBillingPlans.filter(({ is_deleted }) => !is_deleted) : billingPlans.value).reduce(
    (total, { price }) => total + parseAmount(price),
    0,
  );
});

const difference = computed(() => (draft?: boolean) => {
  return feeSummary.value(draft) - parseAmount(form.projectFee);
});

async function submit() {
  lockTabs();
  if (!clientUuid || !projectId) return;
  if (submitMode.value === 'update') saveLoader.start();
  else if (submitMode.value === 'next') nextLoader.start();
  try {
    const activeBillingPlansChanged =
      isActive.value &&
      tracker.differences.value.some((record) => record.path[0] === 'plannedBillingPlans') &&
      noPlannedBillingPlans.value;
    const response = await api.projects.update(
      {
        price_type: form.priceType,
        billing_frequency: form.billingFrequency,
        annual_price: form.projectFee,
        discount: form.discount,
        billing_plans: form.plannedBillingPlans.map((plan) => {
          if (activeBillingPlansChanged) {
            const { id, ...rest } = plan;
            return {
              ...rest,
              parent_project_billing_plan_id: id!,
            };
          }
          return plan;
        }),
        step: ProjectStep.Price,
        estimating_price: 0,
        only_invoice_part_of_year: false,
        number_of_months_for_invoices_per_part_of_year: 1,
      },
      clientUuid,
      projectId,
    );
    setProject(response.data);
    setBillingPlans(response.data.planned_billing_plans, response.data.billing_plans, response.data.step);
    tracker.commit();
    if (submitMode.value === 'next') {
      emit('change-step', ProjectStep.Team);
    }
    if (submitMode.value === 'update') {
      toast.success(t('common.messages.has_been_updated', { name: t('common.project') }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    nextLoader.finish();
    saveLoader.finish();
    unlockTabs();
  }
}

async function getService(uuid: string) {
  try {
    const response = await api.services.get(uuid);
    service.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function generateBillingPlans() {
  if (!form.billingFrequency || !form.projectFee) return;
  const billingCount = calculateBillingCount(project.value.start_date, project.value.end_date, form.billingFrequency);
  const invoicedPlans = form.plannedBillingPlans.filter((plan) => plan.is_invoiced);
  const invoicedFee = invoicedPlans.reduce((total, plan) => total + parseAmount(plan.price), 0);
  const fees = splitFee(form.projectFee - invoicedFee, billingCount);
  const newBillingPlans = createBillingPlans(
    project.value.start_date,
    project.value.end_date,
    project.value.financial_year.start_date,
    project.value.financial_year.end_date,
    form.billingFrequency,
    fees,
  );
  form.plannedBillingPlans = [...invoicedPlans, ...newBillingPlans];
}

function setBillingPlans(
  plannedPlans: IProjectBillingPlanResource[],
  plans: IProjectBillingPlanResource[],
  step: ProjectStep,
) {
  // Set `planned_billing_plans`
  noPlannedBillingPlans.value = plannedPlans.length === 0;
  form.plannedBillingPlans = (noPlannedBillingPlans.value ? plans : plannedPlans)
    .filter((plan) => plan.type === 'base_fee')
    .map((plan) => ({ ...plan, uid: nanoid() }));

  if (form.plannedBillingPlans.length === 0 && step === ProjectStep.Price) {
    generateBillingPlans();
  }

  // Set `billing_plans`
  billingPlans.value = plans.filter((plan) => plan.type === 'base_fee').map((plan) => ({ ...plan, uid: nanoid() }));
}

onMounted(async () => {
  lockTabs();
  loader.start();

  await Promise.all([getService(project.value.service.uuid)]);

  // Set `price_type`
  if (project.value.price_type === null) {
    form.priceType = service.value?.default_price_type ?? ProjectPriceType.FIXED;
  } else {
    form.priceType = project.value.price_type;
  }

  // Set `billing_frequency`
  if (project.value.billing_frequency === null) {
    form.billingFrequency = service.value?.default_invoice_frequency ?? BillingFrequency.MONTHLY;
  } else {
    form.billingFrequency = project.value.billing_frequency;
  }

  // Set `annual_price`
  if (project.value.annual_price === null) {
    form.projectFee = project.value.step === ProjectStep.Price ? service.value?.default_fixed_price ?? null : null;
  } else {
    form.projectFee = project.value.annual_price;
  }

  // Set `discount`
  form.discount = project.value.discount ?? 0;

  setBillingPlans(project.value.planned_billing_plans, project.value.billing_plans, project.value.step);

  // Set billings cells
  const { earliest, latest } = findEarliestAndLatestDates([
    DateTime.fromISO(project.value.start_date).toJSDate(),
    DateTime.fromISO(project.value.end_date).toJSDate(),
    ...form.plannedBillingPlans.map((plan) =>
      DateTime.fromObject({ year: plan.year, month: plan.month, day: 1 }).toJSDate(),
    ),
  ]);
  billingCells.value = createBillingCells(earliest, latest);

  loader.finish();
  tracker.commit();
  unlockTabs();
});

function onProjectFeeChange() {
  const fee = parseAmount(form.projectFee);
  if (fee > 0) {
    generateBillingPlans();
  }
}

function onFrequencyChange() {
  generateBillingPlans();
}

function onPriceTypeChange() {
  if (form.priceType === ProjectPriceType.FIXED) {
    generateBillingPlans();
  } else if (form.priceType === ProjectPriceType.HOURLY) {
    form.plannedBillingPlans = [];
  }
}

function onPlanAdded(plan: IProjectBillingPlan) {
  plan.price = difference.value(true) <= 0 ? Math.abs(difference.value(true)) : 0;
}

const projectShouldBeReactivated = computed(
  () =>
    project.value &&
    isActive.value &&
    project.value.planned_billing_plans.filter((plan) => plan.type === 'base_fee').length > 0,
);
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <form v-else @submit.prevent="submit">
    <AppBox class="mt-4" shadow>
      <AppBoxBody>
        <div class="row">
          <div class="col-lg-10 col-xl-8">
            <!-- Price type -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="price_type" required>{{ t(`project.attributes.price_type`) }}</FormLabel>
                </div>
                <div class="col-md-8">
                  <VueSelect
                    :clearable="false"
                    :filterable="false"
                    v-model="form.priceType"
                    :options="Object.values(ProjectPriceType)"
                    :get-option-label="(option: any) => t(`project.price.${option}`)"
                    input-id="price_type"
                    :placeholder="t('common.select')"
                    required
                    :disabled="!isDraft"
                    @update:model-value="onPriceTypeChange"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Fixed -->
          <template v-if="form.priceType === ProjectPriceType.FIXED">
            <div class="col-lg-10 col-xl-8">
              <!-- Billing frequency -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="billing_frequency" required>
                      {{ t('project.attributes.billing_frequency') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <VueSelect
                      :clearable="false"
                      :filterable="false"
                      v-model="form.billingFrequency"
                      :options="Object.values(BillingFrequency)"
                      :get-option-label="(option: any) => t(`project.billing_frequency.${option}`)"
                      input-id="billing_frequency"
                      :placeholder="t('common.select')"
                      @update:model-value="onFrequencyChange"
                      required
                      :disabled="isDone || isCancelled"
                    />
                  </div>
                </div>
              </div>
              <!-- Annual price -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="annual_price" required>
                      {{ t('project.attributes.annual_price') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-12">
                        <FormInput
                          type="number"
                          v-model.lazy.money="form.projectFee"
                          id="annual_price"
                          icon="currency-krone-swedish"
                          required
                          :min="1"
                          step=".01"
                          @change="onProjectFeeChange"
                          :disabled="isDone || isCancelled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Summaries -->
              <div v-if="!isDraft" class="form-group">
                <div class="row align-items-center">
                  <FormLabel class="col-md-4">{{ t('project.summaries.invoiced') }}</FormLabel>
                  <div class="col-md-8">
                    <table class="summary-table">
                      <tbody>
                        <tr>
                          <td>{{ n(invoiced, 'currency') }}</td>
                          <td v-if="isActive" class="text-neutral-500">{{ t('common.draft') }}:</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <FormLabel class="col-md-4">{{ t('project.summaries.scheduled') }}</FormLabel>
                  <div class="col-md-8">
                    <table class="summary-table">
                      <tbody>
                        <tr>
                          <td>{{ n(scheduled(isDraft), 'currency') }}</td>
                          <td v-if="isActive" class="text-neutral-500" v-text="n(scheduled(true), 'currency')" />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <FormLabel class="col-md-4">{{ t('project.summaries.fee_summary') }}</FormLabel>
                  <div class="col-md-8">
                    <table class="summary-table">
                      <tbody>
                        <tr>
                          <td>{{ n(feeSummary(isDraft), 'currency') }}</td>
                          <td v-if="isActive" class="text-neutral-500" v-text="n(feeSummary(true), 'currency')" />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <FormLabel class="col-md-4">{{ t('project.summaries.difference') }}</FormLabel>
                  <div class="col-md-8">
                    <table class="summary-table">
                      <tbody>
                        <tr>
                          <td v-text="n(difference(isDraft), 'currency')" />
                          <td
                            v-if="isActive"
                            :class="{
                              'text-danger-500': difference(true) !== 0,
                              'text-neutral-500': difference(true) === 0,
                            }"
                            v-text="n(difference(true), 'currency')"
                          />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <BillingPlansGrid
                class="mt-5"
                :title="t('project.invoice_schedule') + (isActive ? ` (${t('project.statuses.draft')})` : '')"
                :cells="billingCells"
                v-model="form.plannedBillingPlans"
                :disabled="isDone || isCancelled"
                @added="onPlanAdded"
                :is-active="isActive"
              />
              <BillingPlansGrid
                v-if="isActive"
                class="mt-5 pt-3"
                :title="t('project.invoice_schedule') + ` (${t('project.statuses.active')})`"
                :cells="billingCells"
                v-model="billingPlans"
                is-active
                disabled
              />
              <!-- Alerts -->
              <AppAlert class="mt-4" v-if="difference(true) !== 0" type="danger">
                <h3 v-text="t('project.messages.the_fee_summary_does_not_match.title')" />
                {{ t('project.messages.the_fee_summary_does_not_match.text') }}
              </AppAlert>
            </div>
          </template>

          <!-- Hourly -->
          <template v-if="form.priceType === ProjectPriceType.HOURLY">
            <div class="col-lg-10 col-xl-8">
              <!-- Discount -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="discount" required>
                      {{ t(`project.attributes.discount`) }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormInput
                      min="0"
                      max="100"
                      type="number"
                      v-model.number="form.discount"
                      id="discount"
                      icon="percentage"
                      required
                      :disabled="!isDraft"
                    />
                  </div>
                </div>
              </div>
              <!-- Estimated annual fee -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="estimating_annual_fee">
                      {{ t(`project.attributes.estimating_annual_fee`) }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormInput
                      type="number"
                      v-model.number="form.projectFee"
                      id="estimating_annual_fee"
                      icon="currency-krone-swedish"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </AppBoxBody>
      <AppBoxBody v-if="projectShouldBeReactivated">
        <AppAlert type="danger">
          {{ t('project.messages.project_should_be_reactivated_billing_plans.text') }}
        </AppAlert>
      </AppBoxBody>
    </AppBox>
    <div class="mt-3 d-flex flex-nowrap">
      <AppButton
        @click.prevent="emit('change-step', ProjectStep.Service)"
        class="mr-2"
        light
        :disabled="saveLoader.isLoading.value || nextLoader.isLoading.value"
      >
        <FontIcon name="chevron-left" />
        {{ t('common.back') }}
      </AppButton>
      <AppButton
        @click="submitMode = 'update'"
        class="ml-auto mr-2"
        color="success"
        :loading="saveLoader.isLoading.value"
        :disabled="disabledUpdate"
      >
        <FontIcon name="device-floppy" />
        {{ t('common.update') }}
      </AppButton>
      <AppButton
        @click="submitMode = 'next'"
        color="secondary"
        :loading="nextLoader.isLoading.value"
        :disabled="disabledNext"
      >
        {{ t('common.next') }}
        <FontIcon name="chevron-right" />
      </AppButton>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.summary-table {
  width: 100%;
  td {
    width: 50%;
  }
}
</style>
