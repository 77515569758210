<script setup lang="ts">
import { ref, onMounted, watch, reactive, computed } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import { DateTime } from 'luxon';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import api from '@/services/api';
import {
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormLabel,
  AppPagination,
  AppLoader,
  AppCollapse,
  ColumnSettings,
  HelpInformation,
  FormSwitch,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useDate from '@/composables/useDate';
import useColumnSettings from '@/composables/useColumnSettings';
import useAuthStore from '@/store/AuthStore';
import { IClientVisitResource } from '@/types/Event';
import { IUserListResource, UserStatusType } from '@/types/User';
import { useTitle } from '@vueuse/core';
import useTimeReportModal from '@/composables/useTimeReportModal';

const { t, d, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { formatYearWeek } = useDate();
const { authenticatedUser } = useAuthStore();
const { openProjectTaskEditModal } = useTimeReportModal();

// Events
const events = ref<IClientVisitResource[]>([]);

// Pagination
const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

// Columns
const { columns, isColumnEnable, enabledColumns } = useColumnSettings('client_visits_columns', [
  'employee',
  'project_responsible',
  'week',
  'client',
  'status',
  'address',
  'task',
  'financial_year',
]);
// Filters
type Filters = {
  assigned_user: null | string;
  project_responsible: null | string;
  date_from: null | string;
  date_to: null | string;
  not_booked: boolean;
};

const filters = reactive<Filters>({
  assigned_user: authenticatedUser.uuid,
  project_responsible: null,
  date_from: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
  date_to: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
  not_booked: true,
});
const filtersLoader = useLoader();

async function resetFilters() {
  filters.assigned_user = authenticatedUser.uuid;
  filters.project_responsible = null;
  filters.date_from = DateTime.now().startOf('month').toFormat('yyyy-MM-dd');
  filters.date_to = DateTime.now().endOf('month').toFormat('yyyy-MM-dd');
  filters.not_booked = true;
  await onFilter();
}

async function getClientVisits() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('per_page', perPage.value.toString());
    // Set filters
    if (filters.assigned_user) searchParams.append('assigned_user', filters.assigned_user);
    if (filters.project_responsible) searchParams.append('project_responsible', filters.project_responsible);
    if (filters.date_from) searchParams.append('date_from', filters.date_from);
    if (filters.date_to) searchParams.append('date_to', filters.date_to);
    searchParams.append('not_booked', Number(filters.not_booked).toString());

    const response = await api.events.clientVisits.index({ searchParams });
    events.value = response.data;
    if (response.meta) {
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  loader.start();
  filtersLoader.start();
  page.value = 1;
  await getClientVisits();
  filtersLoader.finish();
  loader.finish();
}

// Users
const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('statuses[]', UserStatusType.Active);
    searchParams.append('statuses[]', UserStatusType.Invited);
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function onProjectTaskEdit(event: IClientVisitResource) {
  openProjectTaskEditModal(
    { id: event.id },
    {
      onUpdated({ close }) {
        getClientVisits();
        close();
      },
      onSplit({ close }) {
        getClientVisits();
        close();
      },
      async onDeleted({ close }) {
        await Promise.all([getClientVisits(), close()]);
        if (events.value.length === 0 && page.value > 1) {
          page.value -= 1;
        }
      },
    },
  );
}

onMounted(async () => {
  loader.start();
  await Promise.all([getClientVisits(), getUsers()]);
  loader.finish();
});

watch(page, async () => {
  loader.start();
  await getClientVisits();
  loader.finish();
});

const title = useTitle(computed(() => t('client-visit.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="client-visit.index.help" />
      </div>
      <Dropdown class="ml-auto" placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`client-visit.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-5 align-items-end">
          <!-- Employee -->
          <div class="form-group col">
            <FormLabel html-for="filter-assigned_user">
              {{ t('client-visit.index.filters.assigned_user') }}
            </FormLabel>
            <VueSelect
              v-model="filters.assigned_user"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter-assigned_user"
              :loading="usersLoading"
              :disabled="filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
            />
          </div>
          <!-- Project responsible -->
          <div class="form-group col">
            <FormLabel html-for="filter-project_responsible">
              {{ t('client-visit.index.filters.project_responsible') }}
            </FormLabel>
            <VueSelect
              v-model="filters.project_responsible"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter-project_responsible"
              :loading="usersLoading"
              :disabled="filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
            />
          </div>
          <!-- From -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-date_from" required>
              {{ t('client-visit.index.filters.date_from') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-date_from"
                :ui="{ input: 'form-control' }"
                v-model="filters.date_from"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :clearable="false"
                :disabled="filtersLoader.isLoading.value"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- To -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-date_to" required>
              {{ t('client-visit.index.filters.date_to') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-date_to"
                :ui="{ input: 'form-control' }"
                v-model="filters.date_to"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :clearable="false"
                :disabled="filtersLoader.isLoading.value"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Not booked -->
          <div class="form-group col">
            <FormLabel html-for="filter-not_booked">
              {{ t('client-visit.index.filters.not_booked') }}
            </FormLabel>
            <FormSwitch id="filter-not_booked" v-model="filters.not_booked" :disabled="filtersLoader.isLoading.value" />
          </div>
        </div>
        <div>
          <AppButton :disabled="filtersLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filtersLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="events.length === 0">
        {{ t('client-visit.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap v-if="isColumnEnable('employee')">
                {{ t('client-visit.attributes.employee') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('project_responsible')">
                {{ t('client-visit.attributes.project_responsible') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('week')">
                {{ t('client-visit.attributes.week') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('client')">
                {{ t('client-visit.attributes.client') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('status')">
                {{ t('client-visit.attributes.status') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('address')">
                {{ t('client-visit.attributes.address') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('task')">
                {{ t('client-visit.attributes.task') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('financial_year')">
                {{ t('client-visit.attributes.financial_year') }}
              </AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr @click="onProjectTaskEdit(event)" class="pointer" v-for="event in events" :key="event.id">
              <AppTableTd nowrap v-if="isColumnEnable('employee')">
                {{ event.user.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('project_responsible')">
                {{ event.projectResponsible.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('week')">
                {{ formatYearWeek(event.week) }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('client')">
                {{ event.client.name }}
              </AppTableTd>
              <AppTableTd :class="{ 'text-danger-500': !event.visit_is_booked }" nowrap v-if="isColumnEnable('status')">
                {{ t(`client-visit.status.${event.visit_is_booked ? 'booked' : 'not_booked'}`) }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('address')">
                {{ event.client.city }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('task')">
                {{ event.task.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('financial_year')">
                {{ d(event.financialYear.start_date) }} - {{ d(event.financialYear.end_date) }}
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
