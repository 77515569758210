<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';
import { DateTime } from 'luxon';
import { AppAlert, AppButton, AppCloseButton, FormLabel } from '@/components';
import { ITimePeriodResource } from '@/types/TimePeriod';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import useLoading from '@/composables/useLoading';
import api from '@/services/api';

const { t, locale } = useI18n({ useScope: 'global' });

const latestDate = ref<string>('');
const earliestDate = ref<string>('');
const disabledDates = ref<string[]>([]);

const timePeriod = ref<ITimePeriodResource | null>(null);
const timePeriodDate = ref<null | string>(null);

const isInvoicesGenerating = ref<boolean>(false);

const { timePeriods } = defineProps<{ timePeriods: ITimePeriodResource[] }>();

const emit = defineEmits<{
  closed: [];
  create: [timePeriodId: number, params: ConfirmDialogConfirmParams];
}>();

const { loading, setLoading } = useLoading();

function submit() {
  if (timePeriod.value) {
    emit('create', timePeriod.value.id, { setLoading });
  }
}

onMounted(async () => {
  // Set earliest date
  let earliestPeriod = timePeriods[0];
  for (let i = 1; i < timePeriods.length; i++) {
    const currentDate = DateTime.fromObject({ year: timePeriods[i].year, month: timePeriods[i].month });
    const earliest = DateTime.fromObject({ year: earliestPeriod.year, month: earliestPeriod.month });
    if (currentDate < earliest) {
      earliestPeriod = timePeriods[i];
    }
  }
  earliestDate.value = DateTime.fromObject({ day: 1, month: earliestPeriod.month, year: earliestPeriod.year })
    .endOf('month')
    .toFormat('yyyy-MM-dd');

  // Set latest date
  let latestPeriod = timePeriods[0];
  for (let i = 1; i < timePeriods.length; i++) {
    const currentDate = DateTime.fromObject({ year: timePeriods[i].year, month: timePeriods[i].month });
    const latest = DateTime.fromObject({ year: latestPeriod.year, month: latestPeriod.month });
    if (currentDate > latest) {
      latestPeriod = timePeriods[i];
    }
  }
  latestDate.value = DateTime.fromObject({ day: 1, month: latestPeriod.month, year: latestPeriod.year })
    .startOf('month')
    .toFormat('yyyy-MM-dd');

  // Set disabled dates
  const allDates = timePeriods.map((date) => DateTime.fromObject({ day: 1, year: date.year, month: date.month }));
  let currentDate = DateTime.fromISO(earliestDate.value);
  while (currentDate < DateTime.fromISO(latestDate.value)) {
    if (!allDates.some((date) => date.hasSame(currentDate, 'month'))) {
      disabledDates.value.push(
        currentDate.startOf('month').toFormat('yyyy-MM-dd'),
        currentDate.endOf('month').toFormat('yyyy-MM-dd'),
      );
    }
    currentDate = currentDate.plus({ months: 1 });
  }

  const period = timePeriods.find((item) => item.month === DateTime.now().month && item.year === DateTime.now().year);
  if (period) {
    timePeriod.value = period;
    timePeriodDate.value = DateTime.now().toFormat('MM-yyyy');
  }
});

watch(timePeriodDate, (value) => {
  if (value === null) {
    timePeriod.value = null;
  } else {
    const month = parseInt(value.split('-')[0]);
    const year = parseInt(value.split('-')[1]);
    const period = timePeriods.find((item) => item.month === month && item.year === year);
    timePeriod.value = period ?? null;

    if (timePeriod.value) {
      setLoading(true);

      api.invoices
        .isGenerating(timePeriod.value.id)
        .then((resp) => {
          isInvoicesGenerating.value = resp.generating;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="false"
    content-style="overflow: visible;"
    @closed="emit('closed')"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2>{{ t('invoice.create_periodical_invoices.title') }}</h2>
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <AppAlert type="info">
          <div v-html="t('invoice.create_periodical_invoices.description')" />
        </AppAlert>
        <div class="form-group mt-4">
          <FormLabel html-for="time_period" required>{{ t('time-sheet.attributes.time_period') }}</FormLabel>
          <div class="form-wrapper has-icon">
            <VueDatePicker
              :ui="{ input: 'form-control' }"
              :placeholder="t('common.select')"
              v-model="timePeriodDate"
              format="MM-yyyy"
              model-type="format"
              :enable-time-picker="false"
              :month-change-on-scroll="false"
              required
              auto-apply
              month-picker
              :min-date="earliestDate"
              :max-date="latestDate"
              :locale="locale"
              :disabled-dates="disabledDates"
              teleport
              :disabled="loading"
            >
              <template #input-icon><i class="form-icon ti ti-calendar" /></template>
            </VueDatePicker>
          </div>

          <AppAlert type="warning" class="mt-4" v-if="isInvoicesGenerating">
            <div v-html="t('invoice.create_periodical_invoices.generating_warn')" />
          </AppAlert>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="!timePeriod">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
